<template>
    <div id="editapplication" class="baseTemplate">
        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <!-- begin:: home menu -->
                <app-homemenu></app-homemenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">
                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
                        <!-- begin:: Content Head -->
                        <div class="kt-subheader kt-grid__item">
                            <div class="kt-subheader__main">
                                <h3 class="kt-subheader__title">{{ $t("app_editApplication") }}</h3>
                                <span class="kt-subheader__separator kt-subheader__separator--v"></span>
                                <span class="kt-subheader__desc">{{ $t("app_editApplicationSubTitle") }}</span>
                            </div>
                        </div>
                        <!-- end:: Content Head -->

                        <!-- begin:: Content -->
                        <div class="kt-content kt-grid__item kt-grid__item--fluid">
                            <!-- begin:: Content -->
                            <div class="row">
                                <div class="col-md-12">
                                    <!--begin::Portlet-->
                                    <div class="kt-portlet">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <path
                                                                d="M17.2718029,8.68536757 C16.8932864,8.28319382 16.9124644,7.65031935 17.3146382,7.27180288 C17.7168119,6.89328641 18.3496864,6.91246442 18.7282029,7.31463817 L22.7282029,11.5646382 C23.0906029,11.9496882 23.0906029,12.5503176 22.7282029,12.9353676 L18.7282029,17.1853676 C18.3496864,17.5875413 17.7168119,17.6067193 17.3146382,17.2282029 C16.9124644,16.8496864 16.8932864,16.2168119 17.2718029,15.8146382 L20.6267538,12.2500029 L17.2718029,8.68536757 Z M6.72819712,8.6853647 L3.37324625,12.25 L6.72819712,15.8146353 C7.10671359,16.2168091 7.08753558,16.8496835 6.68536183,17.2282 C6.28318808,17.6067165 5.65031361,17.5875384 5.27179713,17.1853647 L1.27179713,12.9353647 C0.909397125,12.5503147 0.909397125,11.9496853 1.27179713,11.5646353 L5.27179713,7.3146353 C5.65031361,6.91246155 6.28318808,6.89328354 6.68536183,7.27180001 C7.08753558,7.65031648 7.10671359,8.28319095 6.72819712,8.6853647 Z"
                                                                fill="#000000"
                                                                fill-rule="nonzero"
                                                            />
                                                            <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-345.000000) translate(-12.000000, -12.000000) " x="11" y="4" width="2" height="16" rx="1" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("app_applicationInfo") }}
                                                </h3>
                                            </div>
                                        </div>
                                        <!--begin::Form-->
                                        <form class="kt-form kt-form--label-right">
                                            <div class="kt-portlet__body">
                                                <div class="kt-section">
                                                    <div class="kt-section__body">
                                                        <div class="form-group row validated">
                                                            <div class="col-lg-6">
                                                                <label>{{ $t("app_applicationName") }} *</label>
                                                                <div class="input-group">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text"><i class="la la-pencil"></i></span>
                                                                    </div>
                                                                    <input @input="$v.appName.$touch()" @keyup="onChangeForm" id="editApplicationNameInput" type="text" class="form-control" :placeholder="$t('app_inputInfoApplicationName')" v-model="appName" />
                                                                </div>
                                                                <div v-if="!$v.appName.required" class="invalid-feedback">
                                                                    {{ $t("error_fieldIsRequired") }}
                                                                </div>
                                                                <div v-else-if="!$v.appName.minLen" class="invalid-feedback">
                                                                    {{ $t("error_minLengthMsg", [$v.appName.$params.minLen.min]) }}
                                                                </div>
                                                                <span class="form-text text-muted">{{ $t("app_detailInfoApplicationName") }}</span>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <label class="">{{ $t("app_applicationTarget") }} *</label>
                                                                <div class="input-group">
                                                                    <select id="editApplicationTargetSelect" class="form-control kt-select2">
                                                                        <!-- To display placeholder -->
                                                                        <option></option>
                                                                    </select>
                                                                </div>
                                                                <span class="form-text text-muted">{{ $t("app_detailInfoApplicationTarget") }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="form-group form-group-last row">
                                                            <div class="col-lg-12">
                                                                <label for="applicationDescription">{{ $t("app_applicationDescription") }}</label>
                                                                <textarea id="newApplicationDescriptionTextArea" @keyup="onChangeForm" class="form-control" rows="3" v-model="appDescription"></textarea>
                                                                <span class="form-text text-muted">{{ $t("app_detailInfoApplicationDescription") }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions">
                                                    <div class="row">
                                                        <div class="col-lg-6 kt-align-right"></div>
                                                        <div class="col-lg-6 kt-align-right">
                                                            <button id="editApplicationUpdateButton" @click="onUpdateApplication" type="button" class="btn btn-brand kt-margin-r-5" :disabled="$v.$invalid || !isFormDataChanged">
                                                                <i class="la la-save"></i>
                                                                {{ $t("common_saveChanges") }}
                                                            </button>
                                                            <button id="editApplicationCancelButton" @click="onCancel" type="reset" class="btn btn-secondary">{{ $t("common_cancel") }}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="kt-portlet">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24" />
                                                            <polygon fill="#000000" opacity="0.3" transform="translate(8.885842, 16.114158) rotate(-315.000000) translate(-8.885842, -16.114158) " points="6.89784488 10.6187476 6.76452164 19.4882481 8.88584198 21.6095684 11.0071623 19.4882481 9.59294876 18.0740345 10.9659914 16.7009919 9.55177787 15.2867783 11.0071623 13.8313939 10.8837471 10.6187476" />
                                                            <path d="M15.9852814,14.9852814 C12.6715729,14.9852814 9.98528137,12.2989899 9.98528137,8.98528137 C9.98528137,5.67157288 12.6715729,2.98528137 15.9852814,2.98528137 C19.2989899,2.98528137 21.9852814,5.67157288 21.9852814,8.98528137 C21.9852814,12.2989899 19.2989899,14.9852814 15.9852814,14.9852814 Z M16.1776695,9.07106781 C17.0060967,9.07106781 17.6776695,8.39949494 17.6776695,7.57106781 C17.6776695,6.74264069 17.0060967,6.07106781 16.1776695,6.07106781 C15.3492424,6.07106781 14.6776695,6.74264069 14.6776695,7.57106781 C14.6776695,8.39949494 15.3492424,9.07106781 16.1776695,9.07106781 Z" fill="#000000" transform="translate(15.985281, 8.985281) rotate(-315.000000) translate(-15.985281, -8.985281) " />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("app_applicationKeys") }}
                                                </h3>
                                            </div>
                                        </div>
                                        <!--begin::Form-->
                                        <form class="kt-form kt-form--label-right">
                                            <div class="kt-portlet__body">
                                                <div class="kt-section">
                                                    <div class="form-group">
                                                        <div role="alert" class="alert alert-secondary">
                                                            <div class="alert-icon">
                                                                <i class="flaticon-questions-circular-button kt-font-brand"></i>
                                                            </div>
                                                            <div class="alert-text">
                                                                {{ $t("app_applicationNotDeployedKeysInfo") }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group form-group-last row">
                                                        <div class="col-lg-6">
                                                            <label>{{ $t("app_applicationId") }}</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-info-circle"></i></span>
                                                                </div>
                                                                <input id="editApplicationIdInput" type="text" class="form-control" v-model="appId" disabled />
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="">{{ $t("app_applicationSecret") }}</label>
                                                            <div class="input-group">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="la la-key"></i></span>
                                                                </div>
                                                                <input id="editApplicationSecretInput" type="text" class="form-control" v-model="appSecret" disabled />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="kt-portlet">
                                        <div class="kt-portlet__head">
                                            <div class="kt-portlet__head-label">
                                                <span class="kt-portlet__head-icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" class="kt-svg-icon">
                                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                            <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
                                                            <path d="M5.74714567,13.0425758 C4.09410362,11.9740356 3,10.1147886 3,8 C3,4.6862915 5.6862915,2 9,2 C11.7957591,2 14.1449096,3.91215918 14.8109738,6.5 L17.25,6.5 C19.3210678,6.5 21,8.17893219 21,10.25 C21,12.3210678 19.3210678,14 17.25,14 L8.25,14 C7.28817895,14 6.41093178,13.6378962 5.74714567,13.0425758 Z" fill="#000000" opacity="0.3" />
                                                            <path d="M11.1288761,15.7336977 L11.1288761,17.6901712 L9.12120481,17.6901712 C8.84506244,17.6901712 8.62120481,17.9140288 8.62120481,18.1901712 L8.62120481,19.2134699 C8.62120481,19.4896123 8.84506244,19.7134699 9.12120481,19.7134699 L11.1288761,19.7134699 L11.1288761,21.6699434 C11.1288761,21.9460858 11.3527337,22.1699434 11.6288761,22.1699434 C11.7471877,22.1699434 11.8616664,22.1279896 11.951961,22.0515402 L15.4576222,19.0834174 C15.6683723,18.9049825 15.6945689,18.5894857 15.5161341,18.3787356 C15.4982803,18.3576485 15.4787093,18.3380775 15.4576222,18.3202237 L11.951961,15.3521009 C11.7412109,15.173666 11.4257142,15.1998627 11.2472793,15.4106128 C11.1708299,15.5009075 11.1288761,15.6153861 11.1288761,15.7336977 Z" id="Shape" fill="#000000" fill-rule="nonzero" transform="translate(11.959697, 18.661508) rotate(-90.000000) translate(-11.959697, -18.661508) " />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <h3 class="kt-portlet__head-title">
                                                    {{ $t("app_applicationDeployment") }}
                                                </h3>
                                            </div>
                                        </div>
                                        <!--begin::Form-->
                                        <form class="kt-form kt-form--label-right">
                                            <div class="kt-portlet__body">
                                                <div class="kt-section">
                                                    <div class="form-group">
                                                        <div role="alert" class="alert alert-secondary">
                                                            <div class="alert-icon">
                                                                <i v-if="isInProduction" class="flaticon-questions-circular-button kt-font-brand"></i>
                                                                <i v-else class="flaticon-questions-circular-button kt-font-brand"></i>
                                                            </div>
                                                            <div v-if="isInProduction" class="alert-text">
                                                                {{ $t("app_applicationDeployedInfo") }}
                                                            </div>
                                                            <div v-else class="alert-text">
                                                                {{ $t("app_applicationNotDeployedInfo") }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form-group form-group-last row">
                                                        <div class="col-lg-6">
                                                            <label class="kt-margin-r-10">{{ $t("app_applicationState") }}</label>
                                                            <span id="editApplicationStateLabel" v-if="isInProduction" class="btn btn-bold btn-sm btn-font-sm btn-label-success kt-font-transform-u">{{ $t("app_deployed") }}</span>
                                                            <span id="editApplicationStateLabel" v-else class="btn btn-bold btn-sm btn-font-sm btn-label-brand kt-font-transform-u">{{ $t("app_notDeployed") }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="kt-portlet__foot">
                                                <div class="kt-form__actions">
                                                    <div class="row">
                                                        <div class="col-lg-6 kt-align-right"></div>
                                                        <div class="col-lg-6 kt-align-right">
                                                            <button id="editApplicationDeployButton" @click="onDeployApplication" type="button" class="btn btn-success" :disabled="isInProduction" v-if="isInProduction == false">
                                                                <i class="la la-rocket"></i>
                                                                {{ $t("app_deployApplication") }}
                                                            </button>
                                                            <button id="editApplicationDeployButton" @click="onUndeployApplication" type="button" class="btn btn-danger" :disabled="!isInProduction" v-if="isInProduction == true">
                                                                <i class="la la-rocket"></i>
                                                                {{ $t("app_undeployApplication") }}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        <!--end::Form-->
                                    </div>
                                    <!--end::Portlet-->
                                </div>
                            </div>
                            <!-- end:: Content -->
                        </div>
                        <!-- end:: Content -->
                    </div>

                    <!-- begin:: Footer -->
                    <app-footer></app-footer>
                    <!-- end:: Footer -->
                    <!-- begin:: Modal to confirm leave page -->
                    <app-confirmleavepagemodal ref="confirmleavepagemodalref"></app-confirmleavepagemodal>
                    <!-- end::Modal -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
const _applicationTargets = require("../../constants/applicationTargets");
import commonVueHelper from "../../helpers/commonVueHelper";
import i18n from "../../i18n";

export default {
    data() {
        return {
            applicationTargets: _applicationTargets.list,
            appId: this.$route.params.appId,
            appName: "",
            appTarget: "",
            appSecret: "",
            appDescription: "",
            appState: "",
            isInProduction: "",
            appStatus: "",
            isFormDataChanged: false
        };
    },
    created: function() {
        console.log("Component(EditApplication)::created() - called");
        this.getCurrentApplication(this.appId);
    },
    mounted: function() {
        console.log("Component(EditApplication)::mounted() - Init metronic layout");
        KTLayout.init();

        this.initApplicationTypeSelect2();
    },
    destroyed: function() {
        console.log("Component(EditApplication)::destroyed() - called");
        this.resetApplicationsState();
    },
    validations: {
        // -- validations -- List of controls to apply to validate a form.
        // --
        appName: {
            required,
            minLen: minLength(4)
        },

        appTarget: {
            required
        }
    },
    watch: {
        // -- Watch -- Property to perform asynchronous or expensive operations in response to changing component "data".
        // --
        currentApplication: function(app) {
            if (app) {
                this.appName = app.name;
                this.appTarget = app.target;
                this.appSecret = app.appSecret;
                this.appDescription = app.description;
                this.appState = app.state;
                this.isInProduction = app.isInProduction;
                this.appStatus = app.isInProduction ? "Production" : "Draft";

                if ($("#editApplicationTargetSelect").data("select2")) {
                    $("#editApplicationTargetSelect").val(app.target);
                    // Trigger change event and provide an additional param
                    $("#editApplicationTargetSelect").trigger({ type: "change", params: { initialInit: true } });
                }
            }
        }
    },
    computed: {
        // -- Computed -- Property will only re-evaluate when some of its reactive dependencies have changed.
        // To compose new data from existing datasource
        // To reference a value from the template (cached value)
        // --
        ...mapGetters(["currentApplication"])
    },
    methods: {
        // -- Methods -- Property to perform an action.
        // To react on some event happening in the DOM (called every time an event occurs even if method returns the same result)
        // To call a function when something happens in your component (from property "computed" or "watch")
        // ---
        ...mapActions(["getCurrentApplication", "updateUserApplication", "updateUserApplicationState", "resetApplicationsState"]),

        // Function called to init application types select2
        initApplicationTypeSelect2() {
            var self = this;
            // Ascending sort order Select2 data of units
            const data = _.orderBy(this.applicationTargets, [], ["asc"]);
            // Init unit Select2
            commonVueHelper.destroySelect2($("#editApplicationTargetSelect"));
            $("#editApplicationTargetSelect")
                .select2({
                    placeholder: i18n.t("app_detailInfoApplicationTarget"),
                    data: data,
                    width: "100%",
                    minimumResultsForSearch: -1
                })
                .val(null)
                .trigger("change")
                // emit event on change.
                .on("change", function(event) {
                    self.appTarget = $(this).val();
                    if (!event.params || (event.params && !event.params.initialInit)) {
                        // Trigger change form only if its a manual action from the user, not the initial init !
                        self.onChangeForm();
                    }
                });
        },

        getBadgeClass() {
            return this.isInProduction ? "kt-badge--success" : "kt-badge--primary";
        },

        getFontClass() {
            return this.isInProduction ? "kt-font-success" : "kt-font-primary";
        },

        // Function called when user click on the "Cancel" button
        onCancel() {
            console.log("Component(EditApplication)::onCancel() - called");
            this.$router.push({ name: "applications", params: { fromAction: "cancelButton" } });
        },

        // Function called when user click on the "Create Application" button
        onUpdateApplication() {
            console.log("Component(EditApplication)::onUpdateApplication() - called");
            let applicationData = {
                id: this.appId,
                name: this.appName,
                target: this.appTarget,
                description: this.appDescription
            };
            // Create the new application in the backend
            this.updateUserApplication(applicationData);
        },

        // Function called when user wants to deploy an application in Production environment
        onDeployApplication() {
            console.log("Component(EditApplication)::onDeployApplication() - called");
            console.log("Component(EditApplication)::onUpdateApplicationState() - called");
            let applicationData = {
                id: this.appId,
                isInProduction: true
            };
            // Create the new application in the backend
            this.updateUserApplicationState(applicationData);
        },

        // Function called when user wants to undeploy an application in Production environment
        onUndeployApplication() {
            console.log("Component(EditApplication)::onUndeployApplication() - called");
            console.log("Component(EditApplication)::onUpdateApplicationState() - called");
            let applicationData = {
                id: this.appId,
                isInProduction: false
            };
            // Create the new application in the backend
            this.updateUserApplicationState(applicationData);
        },

        onChangeForm() {
            this.isFormDataChanged = true;
        }
    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
    },
    filters: {},
    beforeRouteLeave(to, from, next) {
        // -- Called when the route that renders this component is about to be navigated away from.
        commonVueHelper.displayConfirmModalOnLeavePage(this, "EditApplication", "update", this.isFormDataChanged, to, from, next);
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
</style>
